<div class="container-fluid p-0 " style="height: 100%;">
  <div class="row no-gutters p-2" style="height: 100%;background-color: cadetblue;">
    <div class="col mt-0 mb-1 mb-xs-0 mr-2">

      <!-- <app-karta [papperskorgar]="papperskorgar"
                 [selectedPapperskorg]="selectedPapperskorg"
                 [mode]="kartMode"
                 [flyToSelected]="true"
                 [showkoordinates]="true"
                 style="height:100%"
                 (clicked)="onKartaClicked($event)"
                 [clustermarkers]="true">
      </app-karta> -->

      <app-karta [papperskorgar]="papperskorgar"
                 [selectedPapperskorg]="selectedPapperskorg"
                 [mode]="kartMode"
                 [flyToSelected]="true"
                 [showkoordinates]="true"
                 [clustermarkers]="true"
                 style="height:100%"
                 (clicked)="onKartaClicked($event)"
                 (selectedMarkerChanged)="onKartaSelectedMarkerChanged($event)"
                 (papperskorgDblclick)="onKartaPapperskorgDblclick($event)">
      </app-karta>

    </div>

    <!-- Sidebar: hantera papperskorgar -->
    <div class="col my-sidebar">

      <div style="display: flex; flex-direction: column; height:100%">
        <!-- <div>{{kartMode}}</div> -->

        <app-papperskorg-add class="mb-2"
                             *ngIf="isAddMode()"
                             [latitud]="selectedMarkerLatlng?.lat"
                             [longitud]="selectedMarkerLatlng?.lng"
                             (created)="onPapperskorgCreated($event)" (close)="onClose()"></app-papperskorg-add>
        <app-papperskorg-edit class="mb-2"
                              *ngIf="isEditMode() && selectedPapperskorg"
                              [papperskorg]="selectedPapperskorg"
                              [selectedMarkerLatlng]="selectedMarkerLatlng"
                              (deleted)="onPapperskorgDeleted($event)" (updated)="onPapperskorgUpdated($event)" (close)="onClose()"></app-papperskorg-edit>
        <app-papperskorg-list class="mb-2" style="flex: 1;"
                              [papperskorgar]="papperskorgar"
                              [selectedPapperskorg]="selectedPapperskorg"
                              (clicked)="onPapperskorgListClick($event)"></app-papperskorg-list>

        <button type="button" class="btn btn-primary"
                (click)="onNyPapperskorg()">Ny papperskorg...</button>

      </div>
    </div>

  </div>

</div>