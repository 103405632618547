<div class="main-wrapper">
  <div class="menu-row">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="http://www.canmarketing.se/" target="canmarketing">Can Marketing</a>
      <!-- 
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="nav-item nav-link active" routerLink="/">Översikt <span class="sr-only">(current)</span></a>
        </div>
        <div class="navbar-nav">
          <a class="nav-item nav-link active" routerLink="/admin">Admin <span class="sr-only">(current)</span></a>
        </div>
        <div class="navbar-nav">
          <a class="nav-item nav-link active" routerLink="/karttest">Test <span class="sr-only">(current)</span></a>
        </div>
        <div class="navbar-nav">
          <a class="nav-item nav-link active" routerLink="/map">Map! <span class="sr-only">(current)</span></a>
        </div>
      </div> -->

    </nav>

    <!-- <button type="button" class="btn btn-primary" 
        tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
Button with tooltip!
</button> -->
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>