import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { control, LatLng, layerGroup } from 'leaflet';
import { IPapperskorg, IOmrade } from '../entities/papperskorg';
import { PapperskorgService } from '../services/papperskorg.service';
import { KartaComponent, KartMode } from '../karta/karta.component';
import { consoleLog } from '../app';

enum AdminMode {
  Edit = 1,
  Add = 2
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild(KartaComponent, { static: false }) karta: KartaComponent;

  selectedMarkerLatlng: L.LatLng;
  selectedPapperskorg: IPapperskorg;
  papperskorgar: IPapperskorg[] = [];
  adminMode: AdminMode = AdminMode.Edit;
  omraden: IOmrade;
  kartMode: KartMode = KartMode.View;

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  constructor(private papperskorgService: PapperskorgService) {
    this.papperskorgService.papperskorgEmitter
      .subscribe((a) => this.onPapperskorgarChanged(a));
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  ngOnInit() {
    // ladda papperskorgar via service'n
    this.papperskorgService.loadPapperskorgar();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Här kommer nya papperskorgar
  onPapperskorgarChanged(papperskorgar: IPapperskorg[]): void {
    consoleLog('admin.onPapperskorgarChanged -> ', papperskorgar);

    // if(!this.papperskorgar) {
    this.papperskorgar = papperskorgar;
    // }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onClose() {
    this.adminMode = AdminMode.Edit;
    this.kartMode = KartMode.View;
    this.selectedPapperskorg = undefined;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Click från lista
  onPapperskorgListClick(papperskorg: IPapperskorg) {

    this.setSelectedPapperskorg(papperskorg);
    // this.selectedPapperskorg = papperskorg;   // Används av karta
    // this.adminMode = AdminMode.Edit;
    // this.kartMode = KartMode.Edit;            // Används av karta

    // if (!this.selectedPapperskorg) {
    //   this.kartMode = KartMode.View;
    //   this.selectedMarkerLatlng = undefined;
    // } else {
    //   this.selectedMarkerLatlng = L.latLng(this.selectedPapperskorg.latitud, this.selectedPapperskorg.longitud);
    // }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onNyPapperskorg() {
    this.adminMode = AdminMode.Add;
    this.kartMode = KartMode.Add;
    this.selectedPapperskorg = undefined;
    this.selectedMarkerLatlng = undefined;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onKartaClicked(e: L.LatLng) {
    //    this.papperskorgar = this.papperskorgService.getPapperskorgar();
    this.selectedMarkerLatlng = e;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onKartaSelectedMarkerChanged(e: L.LatLng) {
    this.selectedMarkerLatlng = e;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onKartaPapperskorgDblclick(papperskorg: IPapperskorg) {
    this.setSelectedPapperskorg(papperskorg);
  }

  private setSelectedPapperskorg(papperskorg: IPapperskorg) {
    this.selectedPapperskorg = papperskorg; // Används av karta
    this.adminMode = AdminMode.Edit;
    this.kartMode = KartMode.Edit;          // Används av karta
    if (!this.selectedPapperskorg) {
      this.kartMode = KartMode.View;
      this.selectedMarkerLatlng = undefined;
    } else {
      this.selectedMarkerLatlng = L.latLng(this.selectedPapperskorg.latitud, this.selectedPapperskorg.longitud);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onPapperskorgCreated(papperskorg: IPapperskorg) {
    consoleLog('admin, onPapperskorgCreated', papperskorg);

    this.kartMode = KartMode.View;
    this.adminMode = AdminMode.Edit;      // Kan ta bort ?
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onPapperskorgUpdated(papperskorg: IPapperskorg) {
    consoleLog('onPapperskorgUpdated !');

    this.adminMode = AdminMode.Edit;
    this.selectedPapperskorg = null;
    this.kartMode = KartMode.View;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  onPapperskorgDeleted(papperskorg: IPapperskorg) {
    consoleLog('admin, remove papperskorg: ', papperskorg.namn);

    this.selectedPapperskorg = undefined;
    this.kartMode = KartMode.View;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  isAddMode(): boolean {
    return this.adminMode === AdminMode.Add;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  isEditMode(): boolean {
    return this.adminMode === AdminMode.Edit;
  }

}

