<div class="card bg-light" style="height:100%;">
  <div class="card-header">
    <input *ngIf="usefilter" class="input form-control-sm float-right" style="width:100px" type="text" name="filter" [(ngModel)]="filter" placeholder="filter..." />
    <h5>Papperskorgar <small>({{papperskorgar?.length}})</small></h5>
  </div>
  <div class="card-body">
    <!-- <h5>Papperskorgar <small>({{papperskorgar?.length}})</small></h5>
    <hr /> -->

    <!-- 
    <div id="accordion">
      <div class="card">
        <div class="card-header">
          <a class="card-link" data-toggle="collapse" href="#collapseOne">
            Collapsible Group Item #1
          </a>
        </div>
        <div id="collapseOne" class="collapse show" data-parent="#accordion">
          <div class="card-body">
            Lorem ipsum..
          </div>
        </div>
      </div>
    </div>
 -->

    <!-- Yttercontainer -->
    <div class="list-container">

      <!-- Scrollbar innercontainer -->
      <div class="list-scrollable">

        <!-- <div id="accordion" *ngIf="papperskorgarGrupperade">
          <div class="card" *ngFor="let item of papperskorgarGrupperade | keyvalue">
            <div class="card-header">
              <a class="card-link" data-toggle="collapse" href="#{{item.key}}">
                {{item.key}} <span>{{item.value.length}}</span>
              </a>
            </div>
            <div id="{{item.key}}" class="collapse show" data-parent="#accordion">
              <div class="card-body">
                <div>
                  <li *ngFor="let s of item.value" (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg' class='papperskorg'>
                    <span class="badge" *ngIf="s.omrade">{{s.omrade.namn}}</span>&nbsp;
                    <span>{{s.namn || 'papperskorg'}}</span>&nbsp;
                    <span class="badge badge-success la" *ngIf="s.typ?.id===1"> {{s.typ.namn}}</span>
                    <span class="badge badge-sm badge-danger" *ngIf="s.typ?.id===2"> {{s.typ.namn}}</span>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- 
        <div *ngIf="papperskorgarGrupperade">
          <div *ngFor="let item of papperskorgarGrupperade | keyvalue">
            Object Key:{{item.key}}, antal: {{item.value.length}}
            <div *ngFor="let p of item.value">
              {{p.namn}}<br>
            </div>
          </div>
        </div>


| filter: searchText
*ngIf="0"
      -->

        <div *ngIf="0">
          <div *ngFor="let s of papperskorgarSorterade" class='papperskorg' (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg'>
            <!-- <span class="badge" *ngIf="s.omrade">{{s}}</span> -->
            <span class="badge" *ngIf="s.omrade">{{s.omrade.namn}}</span>
            <span>{{s.namn}}</span>
            <span class="badge badge-sm badge-success" *ngIf="s.typ?.id===1"> {{s.typ.namn}}</span>
            <span class="badge badge-sm badge-danger" *ngIf="s.typ?.id===2"> {{s.typ.namn}}</span>
            <span class="badge badge-sm badge-secondary" *ngIf="s.typ?.id===3"> {{s.typ.namn}}</span>

          </div>
        </div>

        <p class="card-text" *ngIf="1">

          <!-- <li *ngFor="let s of papperskorgarSorterade" (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg' class='papperskorg'> -->
          <!-- <li *ngFor="let s of pk" (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg' class='papperskorg'> -->
          <li *ngFor="let s of papperskorgarFiltrerade()" (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg' class='papperskorg'>
            <!-- <li *ngFor="let s of papperskorgar" (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg' class='papperskorg'> -->
            <!-- <li *ngFor="let s of papperskorgarSorterade | filter: filter" (click)="onPapperskorgClick(s)" [class.selected]='s === selectedPapperskorg' class='papperskorg'> -->
            <span class="badge" *ngIf="s.omrade">{{s.omrade.namn}}</span>&nbsp;
            <span>{{s.namn || 'papperskorg'}}</span>&nbsp;
            <span class="badge badge-sm badge-success" *ngIf="s.typ?.id===1"> {{s.typ.namn}}</span>
            <span class="badge badge-sm badge-danger" *ngIf="s.typ?.id===2"> {{s.typ.namn}}</span>
            <span class="badge badge-sm badge-secondary" *ngIf="s.typ?.id===3"> {{s.typ.namn}}</span>
            <!-- <span class="badge badge-sm badge-success"*ngIf="s.typ"> ({{s.typ.id}})</span> -->
            <!-- {{s.latitud | number:'2.4-4'}} : {{s.longitud | number:'2.4-4'}} -->
          </li>
        </p>
      </div>
    </div>
  </div>
</div>