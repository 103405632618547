<div class="karta-wrapper">

  <!-- Visar kartan -->
  <div #karta class="karta" [class.addmode]="this.mode === 3"></div>

  <!-- Visar live koordinater -->
  <div class="koordinater" *ngIf="showkoordinates && mouseLatlng">
    <ng-container>
      <span class="mouseposition">{{mouseLatlng.lat | number:'2.4-4'}} : {{mouseLatlng.lng | number:'2.4-4'}}</span>
      <span class="mouseposition"> {{getZoom()}}</span>
    </ng-container>
  </div>

</div>