<div style="height: 100%" class="oversikt">

  <!-- (clicked)="onKartaClicked($event)" [selectedPapperskorg]="selectedPapperskorg" -->

  <app-karta [papperskorgar]="papperskorgar" style="height:100%"
             [mode]="1"
             [flyToSelected]="false"
             [clustermarkers]="true"
             [showkoordinates]="!isProduction">

  </app-karta>
</div>