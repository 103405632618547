import { Component, OnInit } from '@angular/core';
// import { LatLng } from 'leaflet';
import { IPapperskorg } from '../entities/papperskorg';
import { PapperskorgService } from '../services/papperskorg.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-oversikt',
  templateUrl: './oversikt.component.html',
  styleUrls: ['./oversikt.component.scss']
})
export class OversiktComponent implements OnInit {

  papperskorgar: IPapperskorg[] = [];
  isProduction: boolean = environment.production;

  constructor(private papperskorgService: PapperskorgService) {
    // Lyssna på papperskorgar-ändringar
    this.papperskorgService.papperskorgEmitter
      .subscribe(p => this.onPapperskorgarChanged(p));
  }

  ngOnInit() {
    this.papperskorgService.loadPapperskorgar();
  }

  onPapperskorgarChanged(papperskorgar: IPapperskorg[]): void {
    this.papperskorgar = papperskorgar;
  }

  /*
  // $event
  onMapClick(position: LatLng) {
    // Fire a modal displaying form add
    // alert(position.lat);
  }
*/
}
