export let consoleLogDisabled = (...args: any): void => {
    // do nothing
};

const consoleLogEnabled = (...args: any): void => {
    console.log(...args);
};

export let consoleLog = consoleLogEnabled;
export function enableConsoleLog(enable: boolean) {
    if (enable) {
        consoleLog = consoleLogEnabled;
    } else {
        consoleLog = consoleLogDisabled;
    }
}
