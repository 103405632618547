import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges, NgZone, ChangeDetectorRef } from '@angular/core';
import { IconColors } from '../entities/colors';
import { IPapperskorg } from '../entities/papperskorg';
import * as L from 'leaflet';
import { control, LatLng, layerGroup, LayerGroup, Map, LeafletMouseEvent, MarkerClusterGroupOptions, MarkerClusterGroupLayerSupportOptions } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import { consoleLog } from '../../app/app';
enum PapperskorgTyp {
  Ledig = 1,
  Upptagen = 2,
  Ejtillganglig = 3,
}

export enum KartMode {
  View = 1,
  Edit = 2,
  Add = 3,
}

interface PapperskorgMarker {
  id: number;
  omrade: string;
  marker: L.Marker<any>;
}

@Component({
  selector: 'app-karta',
  templateUrl: './karta.component.html',
  styleUrls: ['./karta.component.scss']
})

export class KartaComponent implements OnInit, OnChanges {

  constructor(
    private ngZone: NgZone,
    private cd: ChangeDetectorRef) {
  }

  @ViewChild('karta', { static: true }) mapholder;    // Referens till kartan
  @Input() papperskorgar: IPapperskorg[];             // Alla papperskorgar
  @Input() mode: KartMode = KartMode.View;            // Aktuellt mode
  @Input() showkoordinates: boolean = false;                    // Visa koordinater
  @Input() flyToSelected: boolean;                    // Flyg till papperskorg när vald
  @Input() clustermarkers: boolean = false;           // Klustra markers

  @Input() selectedPapperskorg: IPapperskorg;

  @Output() clicked = new EventEmitter<LatLng>();                     // Vid ny papperskorg, behövs ?
  @Output() selectedMarkerChanged = new EventEmitter<LatLng>();       // När används denna ?
  @Output() papperskorgDblclick = new EventEmitter<IPapperskorg>();   // När används denna ?

  private accesstoken = 'pk.eyJ1IjoibWlhbm8iLCJhIjoiY2syczdsYTdvMGUwYTNtbnpxdmhmemJ1YiJ9.7lfoEY2epI_gjRPX4vtIcw';
  private karta: Map;

  markersShown: L.Marker[] = [];
  mouseLatlng: LatLng;                            // Syns på kartan
  layerscontrol: L.Control.Layers;                // Layer control i hörnet
  layers = [];                                    // Layer per område
  papperskorgMarkers: PapperskorgMarker[];
  papperskorgMarkerSelected: PapperskorgMarker;   // Ny, håller flyttbar aktuell markör
  markermode = 2;

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  ngOnInit() {
    consoleLog('karta.onInit');
    // Setup karta
    // this.karta = L.map(this.mapholder.nativeElement)

    // Zone'a kartan
    // this.ngZone.runOutsideAngular(() => {
    // this.ngZone.run(() => {

    this.initMap();

    // Add click handlers
    this.karta.on('click', this.onMapClick.bind(this));       // Pass scope to this
    if (this.showkoordinates) {
      this.karta.on('mousemove', this.onMouseMove.bind(this));  // Pass scope to this
      this.karta.on('mouseout', this.onMouseOut.bind(this));    // Pass scope to this
    }

    // });

    // Add click handlers
    // this.karta.on('click', this.onMapClick.bind(this));       // Pass scope to this
    // if (this.showkoordinates) {
    //   this.karta.on('mousemove', this.onMouseMove.bind(this));  // Pass scope to this
    //   this.karta.on('mouseout', this.onMouseOut.bind(this));    // Pass scope to this
    // }
  }

  private initMap() {
    this.karta = L.map(this.mapholder.nativeElement, { zoomSnap: 0.5, zoomDelta: 1, markerZoomAnimation: true })
      .setView([58.4000, 15.6534], 11.5);

    // L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + this.accesstoken, {
    //   attribution: '<a href="https://www.mapbox.com/">Mapbox</a>',
    //   maxZoom: 20,
    //   id: 'mapbox.streets',
    //   accessToken: 'your.mapbox.access.token',
    // }).addTo(this.karta);
    /*
        let layer0 = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=' + this.accesstoken, {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: 'your.mapbox.access.token'
        }).addTo(this.karta);
    
        let layer01 = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=' + this.accesstoken, {
          maxZoom: 18,
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1
        }).addTo(this.karta);
    */

    // Kartor finns här
    // http://leaflet-extras.github.io/leaflet-providers/preview/index.html
    //
    // let layer1 = L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + this.accesstoken, {
    //   attribution: '<a href="https://www.mapbox.com/">Mapbox</a>',
    //   maxZoom: 20,
    //   id: 'mapbox.streets',
    //   accessToken: 'your.mapbox.access.token',
    // }).addTo(this.karta);

    // Open Street Map
    const layer2 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    /*
        // Esri
        const layer3 = L.tileLayer('https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png', {
          maxZoom: 20,
          attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> '
        });
    
        // satellit
        const layer4 = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri'
        });
    
        // Open Street Map HOT
        const layer5 = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
        });
    
        // Open Street Map Sweden
        const layer6 = L.tileLayer('https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png', {
          maxZoom: 20,
          attribution: 'Tiles courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap Sweden</a> &mdash; Map data'
        });
    */
    // L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.{ext}', {
    //   attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //   subdomains: 'abcd',
    //   minZoom: 1,
    //   maxZoom: 16,
    //   ext: 'jpg'
    // }).addTo(this.karta);

    // this.karta.addLayer(layer3);
    this.karta.addLayer(layer2);

  }

  getZoom() {
    return this.karta.getZoom();
  }
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  ngOnChanges(changes: SimpleChanges) {
    consoleLog('karta.onChanges -------- > ', changes);

    if (changes.papperskorgar) {
      consoleLog('karta.onChanges -> changes.papperskorgar');
      this.skapaMarkorer();
    }

    if (changes.mode) {
      consoleLog('karta.onChanges -> changes.mode', changes.mode?.currentValue, this.mode);
      this.setMarkerMode(changes.mode.currentValue);
    }

    if (!!changes.selectedPapperskorg && !!this.selectedPapperskorg) {
      consoleLog('karta.onChanges -> changes.selectedPapperskorg', this.selectedPapperskorg);

      this.setMarkerMode(this.mode);
      // this.setMarkerMode(this.mode);
      if (this.selectedPapperskorg.latitud && this.selectedPapperskorg.longitud) {
        if (this.flyToSelected) {
          setTimeout(() => {
            this.karta.flyTo(L.latLng(this.selectedPapperskorg.latitud, this.selectedPapperskorg.longitud));
          }, 200);

          // this.karta.flyTo(L.latLng(this.selectedPapperskorg.latitud, this.selectedPapperskorg.longitud));
        }
      }
    }
  }

  setMarkerMode(mode: any) {

    //
    // Om KartMode.View, sätt nålar i sina färger - ta bort selectedMarker
    // Om KartMode.Edit, sätt nålar grå, dölj vald nål, skapa en egen blå markör som går att flytta omkring
    // Om KartMode.Add, sätt nålar grå
    //
    this.removeSelectedMarker();

    this.papperskorgMarkers?.forEach(p => {
      if (mode === KartMode.View) {
        // Reset icons
        p.marker.setIcon(this.getIconFromTyp((p as any).typId));
        p.marker.setOpacity(1);

        // Denna ska göras när vi switchar mode till View eller Add
        // this.removeSelectedMarker();
      } else {
        // Allt grått förutom selection
        if (this.selectedPapperskorg?.id === p.id) {
          this.addSelectedMarker(p);
          // Göm editerad markör
          p.marker.setOpacity(0);
        } else {
          p.marker.setIcon(IconColors.greyIcon);
          p.marker.setOpacity(1);
        }
      }
    });
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  removeSelectedMarker() {
    if (this.papperskorgMarkerSelected?.marker) {
      this.karta.removeLayer(this.papperskorgMarkerSelected.marker);
      // this.papperskorgMarkerSelected.marker = undefined;
    }
    this.papperskorgMarkerSelected = undefined;
    //    this.selectedMarker = undefined;
    // this.lastclickLatlng = undefined;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  addSelectedMarker(p: PapperskorgMarker) {
    // Återanvänd marker eller skapa ny
    if (this.papperskorgMarkerSelected?.marker) {
      this.papperskorgMarkerSelected.marker.setLatLng(p.marker.getLatLng());
    } else {
      this.papperskorgMarkerSelected = {
        marker: L.marker(p.marker.getLatLng(), { icon: IconColors.orangeIcon, draggable: true, zIndexOffset: 11000 }), id: -1, omrade: ''
      };
      this.papperskorgMarkerSelected.marker.bindPopup(this.formatteraPopupText(this.selectedPapperskorg));
      this.papperskorgMarkerSelected.marker.on('drag', this.onMarkerDrag.bind(this));              // Setup drag handler
      this.papperskorgMarkerSelected.marker.on('dragend', this.onMarkerDragEnd.bind(this));        // Setup drag handler
      this.papperskorgMarkerSelected.marker.on('dblclick', this.onMarkerDblclick.bind(this));              // Setup drag handler
      this.karta.addLayer(this.papperskorgMarkerSelected.marker);
    }

  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  addSelectedMarker2(latlng: LatLng) {
    // Återanvänd marker eller skapa ny
    if (this.papperskorgMarkerSelected?.marker) {
      this.papperskorgMarkerSelected.marker.setLatLng(latlng);
    } else {
      this.papperskorgMarkerSelected = {
        marker: L.marker(latlng, { icon: IconColors.orangeIcon, draggable: true, zIndexOffset: 11000 }), id: -1, omrade: ''
      };
      // this.papperskorgMarkerSelected.marker.bindPopup(this.formatteraPopupText(this.selectedPapperskorg));
      this.papperskorgMarkerSelected.marker.on('drag', this.onMarkerDrag.bind(this));              // Setup drag handler
      this.papperskorgMarkerSelected.marker.on('dragend', this.onMarkerDragEnd.bind(this));        // Setup drag handler
      this.papperskorgMarkerSelected.marker.on('dblclick', this.onMarkerDblclick.bind(this));              // Setup drag handler
      this.karta.addLayer(this.papperskorgMarkerSelected.marker);
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  formatteraPopupText(p: IPapperskorg): string {
    let popuptext = ((!!p.omrade?.namn) ? (p.omrade?.namn + '<br>') : '');
    popuptext = popuptext + p.namn;
    popuptext = popuptext + ((!!p.typ?.namn) ? ('<br>' + p.typ?.namn) : '');
    popuptext = popuptext + ((!!p.beskrivning) ? ('<hr>' + p.beskrivning) : '');
    return popuptext;
  }

  // Skapa alla kartmarkeringar till en stor array med områdesnamn & marker
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  createMarkersArray() {
    const papperskorgMarkers = this.papperskorgar.map(p => {

      const selected = (this.selectedPapperskorg?.id === p.id);
      // const isEditable = ((KartMode.Edit || KartMode.Add) && selected);

      const options: L.MarkerOptions = {
        icon: this.getIconFromTyp(p.typId)
      };
      //      options.icon = this.getIconFromTyp(p.typId);
      //      options.opacity = 1;
      //      options.draggable = false;

      const marker = {
        id: p.id,
        omrade: p.omrade?.namn ?? 'Övriga',
        marker: L.marker([p.latitud, p.longitud], options),
        typId: p.typId
      };
      marker.marker.bindPopup(this.formatteraPopupText(p));
      marker.marker.on('dblclick', this.onMarkerDblclick.bind(this));              // Setup drag handler
      marker.marker.options.bubblingMouseEvents = false;

      return marker;
    });

    return papperskorgMarkers;
  }

  onMarkerDblclick(e: any) {

    // if (this.papperskorgMarkerSelected === e.target) {
    //   alert('selected !!');
    // }


    // Hitta soptunnan
    const papperskorgMarker = this.papperskorgMarkers.find(x => x.marker === e.target);
    if (papperskorgMarker) {
      const papperskorg = this.papperskorgar.find(x => x.id === papperskorgMarker.id);
      if (papperskorg) {
        this.papperskorgDblclick.emit(papperskorg);
      }
    } else {
      // Borde vara selectedmarker, clear'a selection i så fall...
      this.papperskorgDblclick.emit(null);
    }
  }


  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  skapaMarkorer(rensaGamla: boolean = true) {
    if (rensaGamla) {
      this.rensaMarkorer();
    }

    // TODO: Vi skapar aldrig om markers längre med olika klustringar så denna kan skippas / ändras

    if (this.karta && this.papperskorgar) {
      if (this.mode === KartMode.View) {
        // Markers i clustergrupper
        this.skapaMarkorerClusterLayerGroup();
      } else if (this.mode === KartMode.Edit || this.mode === KartMode.Add) {
        // Markers utan clustergrupper
        this.skapaMarkorerLayerGroup();
      }
    }
  }

  // Ingen klustergrupp
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  skapaMarkorerLayerGroup() {

    if (this.karta && this.papperskorgar) {

      // 1. Hitta unika områden, Distinct, sorterad array of omraden
      const unikaOmraden = Array.from(new Set(this.papperskorgar.map(item => item.omrade?.namn ?? 'Övriga'))).sort();

      // 2. Skapa alla markeringar till en stor array med namn & marker
      this.papperskorgMarkers = this.createMarkersArray();

      // const options: MarkerClusterGroupLayerSupportOptions = {};
      // const mcgLayerSupportGroup = L.markerClusterGroup.layerSupport();
      // mcgLayerSupportGroup.addTo(this.karta);

      //      let mcgLayerSupportGroup = L.markerClusterGroup.layerSupport(null);
      // 3. Loopa dessa och samla papperskorgar i array
      //    Dela upp dom per område till en layergroup-array
      const layerGroups = {};


      unikaOmraden.forEach(o => {

        const layerGroup = L.layerGroup(this.papperskorgMarkers
          .filter(pk => pk.omrade === o)
          .map(z => z.marker));

        const layername = o + ' <small><b>' + layerGroup.getLayers().length + '</b></small>';
        layerGroups[layername] = layerGroup;

        // Spara lagren i vår array
        this.layers.push(layerGroups[layername]);

        // Ut på kartan med lagret
        this.karta.addLayer(layerGroups[layername]);
      });

      // 4. Lägger till lager-kontrollen på kartan
      // L.control.layers(null, layerGroups).addTo(this.karta);
      this.layerscontrol = L.control.layers(null, layerGroups).addTo(this.karta);
    }

  }

  // Med klustergrupp
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  skapaMarkorerClusterLayerGroup() {

    if (this.karta && this.papperskorgar) {

      // 1. Hitta unika områden, Distinct, sorterad array of omraden
      const unikaOmraden = Array.from(new Set(this.papperskorgar.map(item => item.omrade?.namn ?? 'Övriga'))).sort();

      // 2. Skapa alla markeringar till en stor array med namn & marker
      this.papperskorgMarkers = this.createMarkersArray();

      const options: MarkerClusterGroupLayerSupportOptions = {
        singleAddRemoveBufferDuration: 0,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 60,
        disableClusteringAtZoom: 17,
        removeOutsideVisibleBounds: true
      };
      const mcgLayerSupportGroup = L.markerClusterGroup.layerSupport(options);

      mcgLayerSupportGroup.addTo(this.karta);

      //      let mcgLayerSupportGroup = L.markerClusterGroup.layerSupport(null);
      // 3. Loopa dessa och samla papperskorgar i array
      //    Dela upp dom per område till en layergroup-array
      const layerGroups = {};
      unikaOmraden.forEach(o => {

        // Skapa en layergroup

        // layerGroups[o] = L.layerGroup(this.papperskorgMarkers
        const layerGroup = L.layerGroup(this.papperskorgMarkers
          .filter(pk => pk.omrade === o)
          .map(z => z.marker));

        const layername = o + ' <small><b>' + layerGroup.getLayers().length + '</b></small>';
        layerGroups[layername] = layerGroup;

        // Spara lagren i vår array
        this.layers.push(layerGroups[layername]);

        // Ut på kartan med grupperna
        mcgLayerSupportGroup.checkIn(layerGroups[layername]); // <= this is where the magic happens!

        // mcgLayerSupportGroup.
        //         layerGroups.markerClusterGroup.sp

        // 3. Lägg till dessa med namngiven prop (område) i layergroups
        // layerGroups[o].addTo(this.karta);
        this.karta.addLayer(layerGroups[layername]);
      });

      // 4. Lägger till lager-kontrollen på kartan
      //      L.control.layers(null, layerGroups).addTo(this.karta);
      this.layerscontrol = L.control.layers(null, layerGroups).addTo(this.karta);
    }
  }


  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  rensaMarkorer() {
    // if (this.karta && this.markersShown) {
    if (this.karta && this.layers) {
      this.layers.forEach(element => {
        this.karta.removeLayer(element);
      });
      this.layers = [];

      if (this.layerscontrol) {
        this.karta.removeControl(this.layerscontrol);
      }
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  getIconFromTyp(typId: number): L.Icon {
    // if (selected) {
    //   return IconColors.blueIcon;
    // }
    if (this.mode === KartMode.Edit || this.mode === KartMode.Add) {
      return IconColors.greyIcon;
    }
    if (typId === PapperskorgTyp.Ledig) {
      return IconColors.greenIcon;
    }
    if (typId === PapperskorgTyp.Ejtillganglig) {
      return IconColors.greyIcon;
    }

    return IconColors.redIcon;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  onMouseMove(e: LeafletMouseEvent) {
    if (e.latlng) {
      this.mouseLatlng = e.latlng;
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  onMouseOut(e: LeafletMouseEvent) {
    this.mouseLatlng = null;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  onMapClick(e: LeafletMouseEvent) {
    if (this.mode === KartMode.Add) {
      this.addSelectedMarker2(e.latlng);
      this.clicked.emit(e.latlng);
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  onMarkerDrag(e: any) {
    this.selectedMarkerChanged.emit(e.latlng);
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  onMarkerDragEnd(e: any) {
    this.selectedMarkerChanged.emit(e.target.getLatLng());
  }



  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // TODO:
  // onMapClick(e: LeafletMouseEvent) {
  //   if (this.mode === KartMode.Add) {
  //     this.lastPointClicked = e.latlng;

  //     if (!!this.lastPointMarkerClicked) {
  //       this.lastPointMarkerClicked.setLatLng(this.lastPointClicked);
  //     } else {
  //       this.lastPointMarkerClicked = L.marker([this.lastPointClicked.lat, this.lastPointClicked.lng], { autoPan: true, icon: IconColors.blueIcon, title: 'Ny papperskorg', opacity: 1, draggable: true });
  //       this.karta.addLayer(this.lastPointMarkerClicked);                             // Add marker as own layer
  //       this.lastPointMarkerClicked.on('dragend', this.onMarkerDragEnd.bind(this));   // Setup drag handler
  //     }
  //     this.clicked.emit(this.lastPointClicked);                                       // Tell we changed

  //     this.cd.detectChanges();    // Vår vy kommer få ändringar (enbart våran vy och recursivt)
  //   }
  // }

}




