import * as L from 'leaflet';
// import { IPapperskorg } from "src/app/papperskorg";
import { IPapperskorg } from './entities/papperskorg';


// [{id: 1, namn: 'Hemma', latitud: 58.380591041553515, longitud: 15.641634464263918},
// {id: 2, namn: 'Skolan', latitud: 58.379800000000000, longitud: 15.647400000000000}, {id: 4, namn: 'Sjön', latitud: 58.370217285645130, longitud: 15.624446868896486},
// {id: 6, namn: 'Väg', latitud: 58.367336395896600, longitud: 15.609169006347658}, {id: 7, namn: 'Korsning', latitud: 58.382098470235320, longitud: 15.630626678466799},
// {id: 15, namn: 'Rondell', latitud: 58.387947583464390, longitud: 15.591487884521486}, {id: 16, namn: 'Abc', latitud: 58.372017722318160, longitud: 15.594234466552736},
// {id: 17, namn: 'abc', latitud: 58.372017722318160, longitud: 15.594234466552736}, {id: 18, namn: 'aaa', latitud: 58.374898229931720, longitud: 15.575523376464846},
// {id: 19, namn: 'Hjulsbro', latitud: 58.375528309626850, longitud: 15.696544647216797}, {id: 20, namn: 'Natur', latitud: 58.365985897871255, longitud: 15.649509429931642},
// {id: 21, namn: 'Vidingsjö 2', latitud: 58.366526103282396, longitud: 15.661869049072267}, {id: 22, namn: 'Vidingsjö 3', latitud: 58.364905462244660, longitud: 15.655517578125002},
// {id: 23, namn: 'Vidingsjö 4', latitud: 58.365715792065174, longitud: 15.643844604492190}, {id: 24, namn: 'Stångån', latitud: 58.383448351693760, longitud: 15.701179504394533},
// {id: 25, namn: 'Vidingsjö 8', latitud: 58.365265611128280, longitud: 15.641269683837892}, {id: 26, namn: 'Vidingsjö 7', latitud: 58.365175574251886, longitud: 15.645389556884767},
// {id: 27, namn: 'Vidingsjö 9', latitud: 58.364275192855880, longitud: 15.651741027832033}]

// New data
// ,[{"id":1,"namn":"Hemma","latitud":58.380591041553515,"longitud":15.641634464263918},{"id":2,"namn":"Skolan","latitud":58.379800000000000,"longitud":15.647400000000000},{"id":4,"namn":"Sjön","latitud":58.370217285645130,"longitud":15.624446868896486},{"id":6,"namn":"Väg","latitud":58.367336395896600,"longitud":15.609169006347658},{"id":7,"namn":"Korsning","latitud":58.382098470235320,"longitud":15.630626678466799},{"id":15,"namn":"Rondell","latitud":58.387947583464390,"longitud":15.591487884521486},{"id":16,"namn":"Abc","latitud":58.372017722318160,"longitud":15.594234466552736},{"id":17,"namn":"abc","latitud":58.372017722318160,"longitud":15.594234466552736},{"id":18,"namn":"aaa","latitud":58.374898229931720,"longitud":15.575523376464846},{"id":19,"namn":"Hjulsbro","latitud":58.375528309626850,"longitud":15.696544647216797},{"id":20,"namn":"Natur","latitud":58.365985897871255,"longitud":15.649509429931642},{"id":21,"namn":"Vidingsjö 2","latitud":58.366526103282396,"longitud":15.661869049072267},{"id":22,"namn":"Vidingsjö 3","latitud":58.364905462244660,"longitud":15.655517578125002},{"id":23,"namn":"Vidingsjö 4","latitud":58.365715792065174,"longitud":15.643844604492190},{"id":24,"namn":"Stångån","latitud":58.383448351693760,"longitud":15.701179504394533},{"id":25,"namn":"Vidingsjö 8","latitud":58.365265611128280,"longitud":15.641269683837892},{"id":26,"namn":"Vidingsjö 7","latitud":58.365175574251886,"longitud":15.645389556884767},{"id":27,"namn":"Vidingsjö 9","latitud":58.364275192855880,"longitud":15.651741027832033},{"id":28,"namn":"Skogen 2","latitud":58.379488552927235,"longitud":15.616550445556642},{"id":29,"namn":"qwerty","latitud":58.385428084386500,"longitud":15.600242614746096}]



export const SOPTUNNOR: IPapperskorg[] =
  [{ id: 1, namn: 'mock 1', latitud: 58.39100673322486, longitud: 15.642642974853517, omradeId: null, typId: null },
  { id: 2, namn: 'mock 2', latitud: 58.37318795689663, longitud: 15.650711059570314, omradeId: null, typId: null }
  ];

export class Mockdata {

  // getPapperskorgar(): IPapperskorg[] {
  //   let papperskorg: IPapperskorg[] = [];
  //   // let pappers
  //   return null;
  // }


  // Mock
  // Laddar upp markers
  static getMarkers(): L.LatLng[] {

    const markers: L.LatLng[] = [];
    const m =
      [{ lat: 58.39100673322486, lng: 15.642642974853517 },
      { lat: 58.37318795689663, lng: 15.650711059570314 },
      { lat: 58.39217633799949, lng: 15.680580139160156 },
      { lat: 58.39424554371587, lng: 15.617752075195314 },
      { lat: 58.38101852787278, lng: 15.650539398193361 }];

    m.forEach(element => {
      markers.push(new L.LatLng(element.lat, element.lng));
    });

    return markers;
  }

}
