
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AdminComponent } from './admin/admin.component';
import { DataFactoryService } from './services/datafactory.service';
import { AdminFormComponent } from './admin-form/papperskorg-add.component';
import { PapperskorgListComponent } from './papperskorg-list/papperskorg-list.component';
import { PapperskorgEditComponent } from './admin-form/papperskorg-edit.component';
import { KartaComponent } from './karta/karta.component';
import { OversiktComponent } from './oversikt/oversikt.component';
import { ModalComponent } from './appcomponents/modal.component';
import { FilterPipe } from './filter.pipe';
import { ConfigService } from './services/config.service';

// import { ModalBasicComponent } from './modal-basic/modal-basic.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
export function initializeConfig(configService: ConfigService) {
  return () => configService.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminFormComponent,
    PapperskorgListComponent,
    KartaComponent,
    OversiktComponent,
    PapperskorgEditComponent,
    ModalComponent,
    FilterPipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    // NgbModule

  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeConfig,
      deps: [ConfigService],
      multi: true
    },
    DataFactoryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
