<div class="card bg-light" xstyle="max-width: 36rem;" *ngIf="papperskorgKopia">
    <div class="card-header">
        <button type="button" class="close float-right" aria-label="Close" (click)="onClose()" [disabled]="submitted">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5>Redigera papperskorg</h5>
    </div>
    <div class="card-body">
        <div [hidden]="false">
            <div *ngIf="papperskorg?.latitud">
                Koordinater: <small>{{papperskorgKopia.latitud | number:'2.5-5'}} {{papperskorgKopia.longitud | number:'2.5-5'}}</small>
            </div>

            <form (ngSubmit)="processForm(PapperskorgForm)" #PapperskorgForm="ngForm">
                <!-- name -->
                <div class="field form-group">
                    <input type="text" name="name" class="input form-control" placeholder="Ange namn på papperskorgen" [(ngModel)]="papperskorgKopia.namn" required #nameInput="ngModel" [disabled]="submitted">
                    <!-- 
                        <div class="error" *ngIf="nameInput.errors?.required && nameInput.dirty">
                            Papperskorgen måste ha ett namn...
                        </div> 
                        <div class="" *ngIf="nameInput.errors && nameInput.errors.required && nameInput.dirty">
                            namn måste anges...
                        </div>
                    -->

                    <div class="error" *ngIf="nameInput.invalid && nameInput.dirty">
                        Papperskorgen måste ha ett namn...
                    </div>
                    <!-- <div class="help is-error" *ngIf="nameInput.invalid && nameInput.dirty">
                        Papperskorgen måste ha ett...
                    </div> -->
                </div>

                <!-- <div class="form-group">
                    <label for="omradeSelect">Område 1</label>
                    <select class="form-control" id="omradeSelect2">
                        <option *ngFor="let omrade of omraden" value={{omrade.id}} [selected]="omrade.id === papperskorgKopia.omrade?.id">{{omrade.namn}}:{{omrade.id}}:{{papperskorgKopia.omrade?.id}}</option>
                    </select>
                </div> -->

                <div class="form-group">
                    <label for="omradeSelect">Område</label>
                    <select class="form-control" id="omradeSelect" [(ngModel)]="papperskorgKopia.omrade" name="koko" [compareWith]="byId" required #omradeInput="ngModel" [disabled]="submitted">
                        <option value=""></option>
                        <!-- <option *ngFor="let omrade of omraden" [ngValue]="omrade">{{omrade.namn}}:{{omrade.id}}:{{papperskorgKopia.omrade?.id}}</option> -->

                        <option *ngFor="let omrade of omraden" [ngValue]="omrade">{{omrade.namn}}</option>
                    </select>
                </div>
                <div class="error" *ngIf="omradeInput.invalid && omradeInput.dirty">
                    Papperskorgen måste tillhöra ett område...
                </div>

                <div class="form-group">
                    <label for="typSelect">Typ</label>
                    <!-- <select (change)="filterForeCasts($event.target.value)"> -->
                    <select class="form-control" id="typSelect" [(ngModel)]="papperskorgKopia.typId" name="koko2" [disabled]="submitted">
                        <!-- <option value=""></option> -->
                        <option *ngFor="let typ of typer" value={{typ.id}} [selected]="typ.id === papperskorgKopia.typId">{{typ?.namn}}</option>
                    </select>
                </div>

                <!-- 
                <div class="form-group">
                    <label for="exampleFormControlSelect2">Example multiple select</label>
                    <select multiple class="form-control" id="exampleFormControlSelect2">
                        <option *ngFor="let omrade of omraden" value={{omrade?.id}}>{{omrade?.namn}}</option>
                    </select>
                </div> -->

                <!-- 
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Dropdown button
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                </div>
 -->
                <!-- <div>
    <label>Typ: </label>
    <select >
        <option value="0">-- Välj --</option>
        <option *ngFor="let typ of typer" value={{typ?.id}}>
            {{typ?.namn}}
        </option>
    </select>
</div> -->
                <div class="field">
                    <textarea class="textarea form-control" name="message" placeholder="Kommentar kanske ?" [(ngModel)]="papperskorgKopia.beskrivning"></textarea>
                </div>
                <br />
                <button [disabled]="!PapperskorgForm.form.valid" type="submit" class="btn btn-primary" [disabled]="submitted">Spara</button>&nbsp;
                <button (click)="onShowDeleteModal()" type="button" class="btn btn-danger" [disabled]="submitted">Ta bort</button>
                <!-- <button (click)="onClose()" type="button" class="btn btn-secondary float-right" [disabled]="submitted">Stäng</button> -->
            </form>
        </div>
        <div [hidden]="!false">
            Sparar...
        </div>
        <div *ngIf="error">
            FEL !...<!-- {{error.message | json}} -->
        </div>
    </div>
</div>

<app-modal [visible]="showDeleteModal" *ngIf="showDeleteModal" [title]="'Ta bort papperskorg'" [textOk]="'Ok'" [textCancel]="'Avbryt'" (onCancel)="onCloseDeleteModal()" (onOk)="onDeleteModal($event)">
    Ta bort papperskorgen ?
</app-modal>