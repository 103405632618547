import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { IPapperskorg, IOmrade, ITyp } from 'src/app/entities/papperskorg';
import { NgForm } from '@angular/forms';
import { PapperskorgService } from 'src/app/services/papperskorg.service';
import { LatLng } from 'leaflet';
import { consoleLog } from '../app';

@Component({
  selector: 'app-papperskorg-edit',
  templateUrl: './papperskorg-edit.component.html',
  styleUrls: ['./papperskorg-edit.component.scss']
})
export class PapperskorgEditComponent implements OnInit, OnChanges {
  @Input() papperskorg: IPapperskorg;
  @Output() updated = new EventEmitter<IPapperskorg>();
  @Output() deleted = new EventEmitter<IPapperskorg>();
  @Output() close = new EventEmitter();
  @Input() selectedMarkerLatlng: LatLng;

  submitted: boolean;         // Formulär
  showDeleteModal: boolean;
  omraden: IOmrade[];
  typer: ITyp[];
  error: string = null;
  selectedOmrade: any;
  papperskorgKopia: IPapperskorg;

  // Hur ska vi redigera koordinaterna ?
  constructor(private papperskorgService: PapperskorgService) { }

  byId(p1: IOmrade, p2: IOmrade) {
    if (p1 && p2) {
      return p1.id === p2.id;
    }
  }

  ngOnInit() {

    consoleLog('papperskorg-edit: Laddar omraden och typer...');

    this.omraden = this.papperskorgService.getOmraden();
    this.typer = this.papperskorgService.getTyper();

    // this.trashcanService.getOmraden()
    //   .subscribe(omraden => {
    //     consoleLog('Laddat omraden: ');
    //     consoleLog(omraden);
    //     // No hard replace ?
    //     this.omraden = omraden;
    //     //    Meddela alla som lyssnar
    //     //    this.papperskorgEmitter.next(this.papperskorgar);
    //   });

    // this.trashcanService.getTyper()
    //   .subscribe(typer => {
    //     consoleLog('Laddat typer: ');
    //     consoleLog(typer);
    //     this.typer = typer;
    //   });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.papperskorg) {
      // Kopiera över till en 'arbetskopia'
      this.papperskorgKopia = Object.assign({}, this.papperskorg);
    }
    if (changes.selectedMarkerLatlng) {
      this.papperskorgKopia.latitud = changes.selectedMarkerLatlng.currentValue?.lat;
      this.papperskorgKopia.longitud = changes.selectedMarkerLatlng.currentValue?.lng;
    }
  }

  onClose() {
    this.close.emit();
  }

  // Spara
  processForm(papperskorgForm: NgForm) {
    this.submitted = true;

    this.papperskorgKopia.omradeId = this.papperskorgKopia.omrade.id;

    // ~~~~ Uppdatera papperskorg

    // this.trashcanService.updatePapperskorg(this.papperskorgKopia)
    this.papperskorgService.updatePapperskorg(this.papperskorgKopia)
      .subscribe(s => {
        consoleLog('papperskorg-edit -> updatePapperskorg');
        this.submitted = false;

        // Släng in returnerat objekt
        //        // this.papperskorg = s;

        // TODO: Detta flyttas till servicen, behöver ej göras här !
        /*
                consoleLog('papperskorg-edit Papperskorg updated');
        
                // Återför förändringarna till orginalet
                this.papperskorg.latitud = this.papperskorgKopia.latitud;
                this.papperskorg.longitud = this.papperskorgKopia.longitud;
                this.papperskorg.namn = this.papperskorgKopia.namn;
                this.papperskorg.type = this.papperskorgKopia.type;
        
                this.papperskorg.omradeId = s.omradeId;
                this.papperskorg.typId = s.typId;
        
                this.papperskorg.omrade = s.omrade;
                this.papperskorg.typ = s.typ;
        
                // Spara
                // this.papperskorgService.updatePapperskorg(this.papperskorgKopia);
        
                //        this.papperskorg.omrade.id = this.papperskorgKopia.omrade.id;
                //        this.papperskorg.typ.id = this.papperskorgKopia.typ.id;
                //        this.papperskorg.type = this.papperskorgKopia.type;
                //        this.papperskorgKopia = null
        
                // papperskorgForm.pristine = null;
        
                //        papperskorgForm.resetForm();
              */

        // Posta ändrad papperskorg uppåt för dom som bryr sig

        this.updated.emit(s);       // Denna kommer inte fram !

      }, err => {
        this.error = err;
      }
      );
  }

  onShowDeleteModal() {
    this.showDeleteModal = true;
  }

  onCloseDeleteModal() {
    this.showDeleteModal = false;
  }

  onDeleteModal(event: Event) {
    this.delete();
    this.showDeleteModal = true;
  }

  delete() {
    this.submitted = false;
    this.papperskorgService.deletePapperskorg(this.papperskorg)
      .subscribe(x => {
        // Meddela borttaget - vem rensar tunnan Parent ?
        this.deleted.emit(this.papperskorg);
        consoleLog('papperskorg-edit deleted: ' + this.papperskorg.namn);
      },
        err => {
          console.error(err);
        }
      );
  }

}
