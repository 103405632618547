import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { OversiktComponent } from './oversikt/oversikt.component';

const routes: Routes = [
  // { path: '', component: MapComponent },
  { path: '', component: OversiktComponent },
  { path: 'admin', component: AdminComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
