<div id="app-modal-main" *ngIf="visible" class="{{classes}}">
    <div class="modal" style="display:block">
        <div class="modal-dialog {{large?'modal-lg': ''}}">
            <div class="modal-content ">
                <div class="modal-header">
                    <h5 class="modal-title" id="appModalPopoversLabel">{{title}}</h5>
                    <button type="button" class="close" (click)="onCancelClick()" [attr.aria-label]="textCancel">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ng-content></ng-content>
                </div>
                <div class="modal-footer">
                    <button *ngIf="textOk.length" type="button" class="btn btn-primary" (click)="onOkClick()">{{textOk}}</button>
                    <button *ngIf="textDelete.length" type="button" class="btn btn-danger" (click)="onDeleteClick()">{{textDelete}}</button>
                    <button *ngIf="textCancel.length" type="button" class="btn btn-secondary" (click)="onCancelClick()">{{textCancel}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</div>