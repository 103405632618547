import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy, DoCheck } from '@angular/core';
import { IPapperskorg } from '../entities/papperskorg';
import { consoleLog } from '../app';

@Component({
  selector: 'app-papperskorg-list',
  templateUrl: './papperskorg-list.component.html',
  styleUrls: ['./papperskorg-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PapperskorgListComponent implements OnInit, OnChanges, DoCheck {

  @Input() papperskorgar: IPapperskorg[];
  @Output() clicked = new EventEmitter<IPapperskorg>();
  @Input() selectedPapperskorg: IPapperskorg;

  pk: IPapperskorg[];
  papperskorgarSorterade: IPapperskorg[];
  usefilter = true;
  papperskorgarGrupperade: any;
  filter: string = '';
  anrop = 0;

  constructor() { }

  ngOnInit() {
  }

  // papperskorgarFiltrerade2 = () => {
  //   return this.papperskorgarSorterade?.filter(x => !this.filter || x.omrade?.namn.toLowerCase().includes(this.filter?.toLowerCase()));
  // }
  // papperskorgarFiltrerade2 = this.papperskorgarSorterade?.filter(x => !this.filter || x.omrade?.namn.toLowerCase().includes(this.filter?.toLowerCase()));


  // papperskorgarFiltrerade2 = this.papperskorgarSorterade?.filter(x => true);


  // some debugging code
  ngDoCheck() {
    // consoleLog('DO_CHECK', new Date().toLocaleTimeString());
  }

  papperskorgarFiltrerade(): IPapperskorg[] {
    if (!this.papperskorgarSorterade) {
      return this.papperskorgarSorterade;

    }
    //  return [];
    this.anrop++;
    consoleLog('papperskorgarFiltrerade: ' + this.filter + ' : ' + this.papperskorgarSorterade.length + ' : ' + this.anrop);
    if (this.usefilter) {
      return this.papperskorgarSorterade.filter(x => !this.filter || x.omrade?.namn.toLowerCase().includes(this.filter.toLowerCase()));
    }
    return this.papperskorgarSorterade;
    return this.papperskorgar.slice(0, 1800);
    // return this.papperskorgar.filter(x => !term || x.omrade?.namn.toLowerCase().includes(term.toLowerCase()));
  }

  // apa() {
  //   consoleLog('Apan called !');
  //   return 'Apan!';
  // }

  ngOnChanges(changes: SimpleChanges) {
    // *ngIf="0"
    consoleLog('papperskorg-list ngOnChanges -->');
    if (!!changes.papperskorgar && !changes.papperskorgar.firstChange) {
      // consoleLog('papperskorg-list ngOnChanges: papperskorgar');

      this.papperskorgarSorterade = this.papperskorgar.map(x => x);
      // this.papperskorgarSorterade = this.papperskorgarSorterade.sort((a, b) => (a.omrade?.namn ?? 'Övrigt' > b.omrade?.namn ?? 'Övrigt') ? 1 : (a.omrade?.namn ?? 'Övrigt' === b.omrade?.namn ?? 'Övrigt') ? ((a.namn > b.namn) ? 1 : -1) : -1);
      this.papperskorgarSorterade = this.papperskorgarSorterade.sort((a, b) => (a.omrade?.namn > b.omrade?.namn) ? 1 : (a.omrade?.namn === b.omrade?.namn) ? ((a.namn > b.namn) ? 1 : -1) : -1);

      this.papperskorgarGrupperade = this.papperskorgarSorterade.reduce((accumulator, object) => {
        const key = object.omrade?.namn ?? 'Övrigt';
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        accumulator[key].push(object);
        return accumulator;
      }, {});
      // consoleLog('ngOnChanges end: papperskorgar');
    }

    // const groupBy = (OurArray, property) => {
    //   return OurArray.reduce((accumulator, object) => {
    //     const key = object[property];
    //     // using ternary operator to make it shorter but in this case it is not necessary as it might look complicated
    //     !accumulator[key] ? (accumulator[key] = []) : (accumulator[key].push(object));
    //     return accumulator;
    //   }, {});
    // };

    // let a = groupBy(this.papperskorgarSorterade, 'omrade.namn');

    // consoleLog('papperskorg-list.onChanges', changes);

  }



  onPapperskorgClick(papperskorg: IPapperskorg) {

    if (papperskorg === this.selectedPapperskorg) {
      this.selectedPapperskorg = null;
    } else {
      this.selectedPapperskorg = papperskorg;
    }

    this.clicked.emit(this.selectedPapperskorg);
  }

}
