import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() large: boolean = false;
  @Input() visible: boolean = false;
  @Input() classes: string = '';
  @Input() title: string = 'Modal';
  @Input() textOk: string = '';
  @Input() textCancel: string = '';
  @Input() textDelete: string = '';
  @Output() onCancel = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onOk = new EventEmitter();

  constructor() { }

  async ngOnInit() { }

  onOkClick() {
    if (this.onOk) {
      this.onOk.emit();
    }
  }

  onCancelClick() {
    if (this.onCancel) {
      this.onCancel.emit();
    }
  }

  onDeleteClick() {
    if (this.onDelete) {
      this.onDelete.emit();
    }
  }

}
