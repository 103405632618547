import { Injectable } from '@angular/core';
import { DataFactoryService } from './datafactory.service';
import { IPapperskorg, IOmrade, ITyp } from '../entities/papperskorg';
import { BehaviorSubject, Observable } from 'rxjs';
import { publish, refCount } from 'rxjs/operators';
import { consoleLog } from '../app';

@Injectable({
  providedIn: 'root'
})
export class PapperskorgService {

  private papperskorgar: IPapperskorg[] = [];
  private omraden: IOmrade[] = [];
  private typer: ITyp[] = [];

  constructor(private trashcanService: DataFactoryService) { }

  papperskorgEmitter: BehaviorSubject<IPapperskorg[]> = new BehaviorSubject(undefined);

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  getOmraden(): IOmrade[] {

    if (this.omraden.length > 0) {
      return this.omraden;
    }

    this.trashcanService.getOmraden()
      .subscribe(omraden => {
        consoleLog('papperskorg.service: laddat omraden -------> ', omraden);

        this.omraden.splice(0, this.omraden.length);
        omraden.forEach(x => this.omraden.push(x));
      });
    return this.omraden;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  getTyper(): ITyp[] {
    if (this.typer.length > 0) {
      return this.typer;
    }

    this.trashcanService.getTyper()
      .subscribe(typer => {
        consoleLog('papperskorg.service: laddat typer --------> ', typer);

        this.typer.splice(0, this.typer.length);
        typer.forEach(x => this.typer.push(x));
      });
    return this.typer;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  loadPapperskorgar(): IPapperskorg[] {
    // Get Papperskorgar
    // this.papperskorgar = [];

    this.trashcanService.getPapperskorgar()
      .subscribe(trashcans => {
        consoleLog('papperskorg.service: laddat papperskorgar ---------> ', trashcans);

        // No hard replace ?

        this.papperskorgar = trashcans;

        // Meddela alla som lyssnar
        this.papperskorgEmitter.next(this.papperskorgar);

      });

    return this.papperskorgar;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  getPapperskorgar(): IPapperskorg[] {
    if (!this.papperskorgar) {
      this.loadPapperskorgar();
    }
    return this.papperskorgar;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // No Use - varför lägga till och inte spara ?

  // addPapperskorg(papperskorg: IPapperskorg) {
  //   this.papperskorgar.push(papperskorg);
  // }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // No Use
  clearPapperskorgar() {
    this.papperskorgar = [];
    //    this.papperskorgEmitter.next(this.papperskorgar);
    return this.papperskorgar;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  insertPapperskorg(papperskorg: IPapperskorg): Observable<IPapperskorg> {

    const observable = this.trashcanService.insertPapperskorg(papperskorg)
      .pipe(publish(), refCount());

    observable.subscribe(res => {
      this.papperskorgar.push(res);
      this.papperskorgar = [].concat(this.papperskorgar);
      this.papperskorgEmitter.next(this.papperskorgar);
      consoleLog('Papperskorg.Service -> Papperskorg inserted');
    });

    return observable;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  updatePapperskorg(papperskorg: IPapperskorg): Observable<IPapperskorg> {

    const observable = this.trashcanService.updatePapperskorg(papperskorg)
      .pipe(publish(), refCount());

    observable.subscribe(uppdateradPapperskorg => {
      const index = this.papperskorgar.findIndex(p => p.id === uppdateradPapperskorg.id);
      if (index > -1) {
        this.papperskorgar[index] = uppdateradPapperskorg;
        this.papperskorgar = [].concat(this.papperskorgar);
        this.papperskorgEmitter.next(this.papperskorgar);
      }
      consoleLog('Papperskorg.Service -> Papperskorg updated');
    }, err => {
      consoleLog('Papperskorg.Service -> Papperskorg updated, error !');
    }, () => {
      consoleLog('Papperskorg.Service -> Papperskorg updated, complete !');
    });

    return observable;
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  deletePapperskorg(papperskorg: IPapperskorg): Observable<IPapperskorg> {

    const observable = this.trashcanService.deletepapperskorg(papperskorg)
      .pipe(publish(), refCount());

    observable.subscribe(res => {
      const index = this.papperskorgar.indexOf(papperskorg);
      if (index > -1) {
        this.papperskorgar.splice(index, 1);
        // Lura angular att vi har en ny array så onChanges triggas
        this.papperskorgar = [].concat(this.papperskorgar);
        this.papperskorgEmitter.next(this.papperskorgar);
      }

      consoleLog('Papperskorg.Service -> Papperskorg deleted');
    }, err => {
      consoleLog('Papperskorg.Service -> Papperskorg deleted, error !');
    }, () => {
      consoleLog('Papperskorg.Service -> Papperskorg deleted, complete !');
    });

    return observable;
  }

}
