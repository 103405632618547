import * as L from 'leaflet';

export class IconColors {

    public static  blueIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-blue.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static  goldIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-gold.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static redIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-red.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static greenIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-green.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static orangeIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-orange.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static yellowIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-yellow.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static violetIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-violet.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static greyIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-grey.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    public static blackIcon = new L.Icon({
        iconUrl: 'styles/leaflet-color-markers/img/marker-icon-2x-black.png',
        shadowUrl: 'styles/leaflet-color-markers/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });
}
