import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { IPapperskorg, IOmrade, ITyp } from '../entities/papperskorg';
import { NgForm } from '@angular/forms';
import { PapperskorgService } from '../services/papperskorg.service';

@Component({
  selector: 'app-papperskorg-add',
  templateUrl: './papperskorg-add.component.html',
  styleUrls: ['./papperskorg-add.component.scss']
})
export class AdminFormComponent implements OnInit {
  @ViewChild('PapperskorgForm') papperskorgForm: NgForm;
  @Input() latitud: number;
  @Input() longitud: number;
  @Output() created = new EventEmitter<IPapperskorg>();
  @Output() close = new EventEmitter();
  namn: string;
  omradeId: number;
  typId: number;
  beskrivning: string;
  submitted: boolean;
  omraden: IOmrade[];
  typer: ITyp[];
  constructor(private papperskorgService: PapperskorgService) { }

  ngOnInit() {
    this.omraden = this.papperskorgService.getOmraden();
    this.typer = this.papperskorgService.getTyper();

    // Sätt default 1

    // this.papperskorgForm.setValue({ typId: 1 });
    setTimeout(() => {
      this.setDefault();
    });
    // typ.id
    // this.papperskorgForm.setValue({ namn: 'haha' });
  }

  // Går på name...
  setDefault() {
    this.papperskorgForm.setValue({ name: '', omrade: '', message: '', koko2: '1' });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (!!changes.papperskorg) {
  //     // Kopiera över till en 'arbetskopia'
  //     this.papperskorgKopia = Object.assign({}, this.papperskorg);
  //   }
  //  }

  onClose() {
    this.close.emit();
  }

  processForm(papperskorgForm: NgForm) {

    this.submitted = true;

    const papperskorg: IPapperskorg = {
      id: null,
      latitud: this.latitud,
      longitud: this.longitud,
      namn: this.namn,
      omradeId: this.omradeId,
      typId: this.typId,
      beskrivning: this.beskrivning,
    };

    // TODO: Hantera fail
    this.papperskorgService.insertPapperskorg(papperskorg)
      .subscribe(s => {
        // Töm formulär
        this.submitted = false;
        this.latitud = null;
        this.longitud = null;
        papperskorgForm.resetForm();
        this.created.emit(s);
      });
  }

}
