import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private apiUrl: string;

    constructor(private http: HttpClient) { }

    async loadConfig(): Promise<any> {
        const config = await this.http.get('/assets/config.json')
            .toPromise();
        this.apiUrl = config['apiUrl'];
    }

    // loadConfig(): Promise<any> {
    //     return this.http.get('/assets/config.json')
    //         .toPromise()
    //         .then(config => {
    //             this.apiUrl = config['apiUrl'];
    //         });
    // }

    getApiUrl(): string {
        return this.apiUrl;
    }
}
