<div class="card bg-light" xstyle="max-width: 36rem;">
  <!-- <div class="card-header text-white bg-primary border-primary"> -->

  <div class="card-header">
    <button type="button" class="close float-right" aria-label="Close" (click)="onClose()" [disabled]="submitted">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5>Skapa papperskorg</h5>
  </div>

  <div class="card-body">
    <div [hidden]="submitted">
      <div *ngIf="latitud">
        Koordinater: <small>{{latitud | number:'2.5-5'}} {{longitud | number:'2.5-5'}}</small>
      </div>
      <div class="error" *ngIf="!latitud">
        Koordinater saknas, markera position på kartan
      </div>

      <form (ngSubmit)="processForm(PapperskorgForm)" #PapperskorgForm="ngForm">
        <div class="field form-group">
          <input type="text" name="name" [(ngModel)]="namn" #nameInput="ngModel"
                 class="input form-control"
                 placeholder="Ange namn på papperskorgen" required
                 [ngClass]="{ 'is-invalid': nameInput.invalid && nameInput.dirty }">

          <!-- minlength="3" -->
          <!-- <div class="" *ngIf="nameInput.errors?.required && nameInput.dirty">
            namn måste anges !...
          </div> -->
          <!-- <div class="" *ngIf="nameInput.errors && nameInput.errors.required && nameInput.dirty">
          namn måste anges...
        </div>           -->
          <!-- <div class="help is-error" *ngIf="nameInput.invalid && nameInput.dirty">
                        Punkten måste ha ett namn på minst 3 bokstäver...
                      </div> -->

          <div class="invalid-feedback" *ngIf="nameInput.invalid && nameInput.dirty">
            Papperskorgen måste ha ett namn...
          </div>
        </div>
        <div class="form-group">
          <!-- [(ngModel)]="omrade" required #omradeInput="ngModel" -->
          <select class="form-control" id="omradeSelect2" *ngIf="omraden" [(ngModel)]="omradeId" name="omrade" required #omradeInput="ngModel">
            <!-- <option value=""></option> -->
            <option *ngFor="let omrade of omraden" value={{omrade.id}}>{{omrade.namn}}</option>
          </select>
        </div>
        <!-- <div class="error" *ngIf="!omradeId">
          Papperskorgen måste tillhöra ett område...
        </div> -->

        <div class="form-group">
          <select class="form-control" id="typSelect" *ngIf="typer" [(ngModel)]="typId" name="koko2">
            <!-- <option value=""></option> -->
            <option *ngFor="let typ of typer" value={{typ.id}}>{{typ?.namn}}</option>
          </select>
        </div>

        <div class="field">
          <textarea class="textarea form-control" name="message" placeholder="Beskrivning kanske ?" [(ngModel)]="beskrivning"></textarea>
        </div>

        <br />

        <button [disabled]="!PapperskorgForm.form.valid || !latitud" type="submit" class="btn btn-primary">Spara</button>
        <!-- <button (click)="onClose()" type="button" class="btn btn-secondary float-right" [disabled]="submitted">Stäng</button> -->
      </form>
    </div>
    <div [hidden]="!submitted">
      Sparar...
    </div>
  </div>
  <!-- <div class="card-footer">
    <button [disabled]="!PapperskorgForm.form.valid || !latitud" type="submit" class="btn btn-primary">Spara</button>
    <button (click)="onClose()" type="button" class="btn btn-secondary float-right" [disabled]="submitted">Stäng</button>
  </div> -->

</div>