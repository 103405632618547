import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SOPTUNNOR } from '../mock-data';
import { IPapperskorg, IOmrade, ITyp } from '../entities/papperskorg';
import { environment } from '../../environments/environment';
import { consoleLog } from '../app';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DataFactoryService {

  private apiUrl: string;
  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private mock: boolean = false;

  constructor(private http: HttpClient, private configService: ConfigService) {
    consoleLog("DataFactoryService: constructor enter");
    // TODO: Load apiUrl from external json file

    this.apiUrl = environment.apiUrl;
    // loaded from configfile
    this.apiUrl = this.configService.getApiUrl();
    /*     let config = this.getConfig();
    
        config.subscribe(res =>
          consoleLog("DataFactoryService,Subscribe: ", res));
     */

    // this.apiUrl = config.apiUrl;
    consoleLog("DataFactoryService: constructor exit");
  }

  /*   Init() {}
  
    getConfig() {
      return this.http.get('/assets/config.json');
    }
   */
  getPapperskorgar(): Observable<IPapperskorg[]> {
    if (this.mock) {
      return of(SOPTUNNOR);
    }
    return this.http.get<IPapperskorg[]>(`${this.apiUrl}/papperskorg`);
  }

  getPapperskorgById(id: number): Observable<IPapperskorg> {
    if (this.mock) {
      return of(SOPTUNNOR.find(x => x.id === id));
    }
    return this.http.get<IPapperskorg>(`${this.apiUrl}/papperskorg/${id}`);
  }

  updatePapperskorg(soptunna: IPapperskorg): Observable<IPapperskorg> {
    return this.http.put<IPapperskorg>(`${this.apiUrl}/papperskorg/${soptunna.id}`, soptunna, this.httpOptions);
  }

  insertPapperskorg(soptunna: IPapperskorg): Observable<IPapperskorg> {
    return this.http.post<IPapperskorg>(`${this.apiUrl}/papperskorg`, soptunna, this.httpOptions);
  }

  deletepapperskorg(soptunna: IPapperskorg): Observable<IPapperskorg> {
    return this.http.delete<IPapperskorg>(`${this.apiUrl}/papperskorg/${soptunna.id}`, this.httpOptions);
  }

  getOmraden(): Observable<IOmrade[]> {
    // if (this.mock) {
    //   return of(SOPTUNNOR);
    // }
    return this.http.get<IOmrade[]>(`${this.apiUrl}/omrade`);
  }

  getTyper(): Observable<ITyp[]> {
    // if (this.mock) {
    //   return of(SOPTUNNOR);
    // }
    return this.http.get<ITyp[]>(`${this.apiUrl}/typ`);
  }

}
